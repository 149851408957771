import { Fragment, useRef, useState } from 'react'
import { DocumentDuplicateIcon, QrCodeIcon } from '@heroicons/react/24/outline'
import {
  Dialog,
  DialogPanel,
  DialogTitle,
  Transition,
  TransitionChild,
} from '@headlessui/react'
import { CopyToClipboardMenu } from './CopyToClipboardMenu'
import { DownloadMenu } from './DownloadMenu'

type LinkProps = {
  url: string
  slug: string
}

export const Link = ({ slug, url }: LinkProps) => {
  const [isOpen, setIsOpen] = useState(false)
  const short = `https://shre.ink/${slug}`
  const copyLinkRef = useRef<HTMLButtonElement>(null)

  const handleCopyLinkClick = () => {
    navigator.clipboard.writeText(short)
    copyLinkRef.current?.classList.add('animate')
    setTimeout(() => {
      copyLinkRef.current?.classList.remove('animate')
    }, 800)
  }

  const handleQrCodeClick = () => {
    setIsOpen(true)
  }

  const handleCloseDialog = () => {
    setIsOpen(false)
  }

  return (
    <>
      <div className="flex bg-white p-4 border border-orange-100 hover:border-orange-200 rounded-md gap-4 items-center shadow-sm">
        <div className="min-w-10 min-h-10">
          <img
            alt={url}
            src={`https://www.google.com/s2/favicons?sz=64&domain_url=${url}`}
            width="40"
            height="40"
          />
        </div>
        <div className="flex flex-col gap-2 overflow-hidden">
          <a
            href={short}
            target="_blank"
            rel="noreferrer"
            className="text-orange-700 font-bold"
          >
            {short}
          </a>
          <a
            href={url}
            target="_blank"
            rel="noreferrer"
            className="text-gray-600 truncate"
          >
            {url}
          </a>
        </div>
        <div className="flex gap-2 ml-auto">
          <button
            type="button"
            className="bg-gray-100 hover:bg-orange-100 hover:text-orange-500 transition-all duration-75 p-2 rounded-full w-9 h-9 confetti"
            data-url={short}
            data-confetti-text="Link copiado!"
            ref={copyLinkRef}
            onClick={handleCopyLinkClick}
            aria-label="Copiar link"
          >
            <DocumentDuplicateIcon />
          </button>
          <button
            type="button"
            className="bg-gray-100 hover:bg-orange-100 hover:text-orange-500 transition-all duration-75 p-2 rounded-full w-9 h-9 confetti"
            onClick={handleQrCodeClick}
            aria-label="Abrir QR code"
          >
            <QrCodeIcon />
          </button>
        </div>
      </div>

      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={handleCloseDialog}>
          <TransitionChild
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black/25 backdrop-blur-sm" />
          </TransitionChild>
          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <TransitionChild
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <DialogPanel className="flex flex-col gap-4 w-full max-w-md transform overflow-visible rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <DialogTitle
                    as="h3"
                    className="text-xl font-bold text-center"
                  >
                    Baixar QR Code
                  </DialogTitle>
                  <div className="flex items-center justify-center">
                    <img
                      alt={`QR Code de ${short}`}
                      src={`api/qr?data=${short}`}
                      className="w-[128px] h-[128px] p-2 border rounded"
                    />
                  </div>
                  <div className="flex justify-center gap-2">
                    <CopyToClipboardMenu data={short} />
                    <DownloadMenu data={short} />
                  </div>
                </DialogPanel>
              </TransitionChild>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  )
}
